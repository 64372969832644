import React from "react";
import "./GenerativeStyle.css";

const GenerativeHomeConstruction = () => {
    return (
        <div className="desktop-homepage">
            <div className="homepage-content">
                <div className="welcome-text">🚧 Under Construction 🚧</div>
                <p>
                    This page is currently being updated. Please check back
                    later.
                </p>
            </div>
        </div>
    );
};

export default GenerativeHomeConstruction;
