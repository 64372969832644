import React, { useEffect, useState } from "react";
import JourneyUnit from "./JourneyUnit";
import "./JourneyPath.css";
import JourneyStepModal from "../popups/JourneyStepModal";
import { useJourney } from "../common/JourneyContext";

const JourneyPath = () => {
    const { journey } = useJourney();
    const [currentStepIndex, setCurrentStepIndex] = useState(null);
    const [isStepOpen, setIsStepOpen] = useState(false);
    const [selectedStep, setSelectedStep] = useState(null);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        if (journey) {
            if (selectedStep) {
                const matchingStep = journey.find(
                    (step) => step.index === selectedStep.index,
                );
                if (matchingStep) {
                    setSelectedStep(matchingStep);
                }
            }

            // Set current step
            const currentStep = journey.find((item) => item.current_step);
            if (currentStep) {
                setCurrentStepIndex(currentStep.index);
            }
        }
    }, [journey]);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    function splitJourneyByUnit(journey) {
        const journeyUnits = [];
        const completeStatus = [];
        let previousRowCounter = [0];

        // Group items by unit
        journey.forEach((item) => {
            const unitIndex = item.unit - 1;

            if (!journeyUnits[unitIndex]) {
                journeyUnits[unitIndex] = [];
            }

            journeyUnits[unitIndex].push(item);
        });

        // Determine how many rows will exist in the previous units
        journeyUnits.forEach((unit, unitIndex) => {
            if (unitIndex > 0) {
                // Calculate cumulative rows from previous units
                previousRowCounter = [
                    ...previousRowCounter,
                    previousRowCounter[unitIndex - 1] +
                        Math.ceil(journeyUnits[unitIndex - 1].length / 7),
                ];
            }
        });

        // Determine if all items in each sublist have status "complete"
        journeyUnits.forEach((sublist) => {
            const allComplete = sublist.every(
                (item) => item.status === "complete",
            );
            completeStatus.push(allComplete);
        });

        return { journeyUnits, completeStatus, previousRowCounter };
    }

    const { journeyUnits, completeStatus, previousRowCounter } =
        splitJourneyByUnit(journey);

    if (windowWidth < 1000) {
        return null;
    }

    return (
        <div
            className="journey-path-container hide-on-mobile-1000"
            style={{ marginBottom: "30px" }}
        >
            {journeyUnits.map((journeyUnit, index) => (
                <JourneyUnit
                    key={index}
                    unitNumber={index}
                    totalNumberUnits={journeyUnits.length}
                    unit={journeyUnit}
                    complete={completeStatus[index]}
                    previousRowCount={previousRowCounter[index]}
                    currentStepIndex={currentStepIndex}
                    setIsStepOpen={setIsStepOpen}
                    setSelectedStep={setSelectedStep}
                />
            ))}
            <JourneyStepModal
                isStepOpen={isStepOpen}
                setIsStepOpen={setIsStepOpen}
                step={selectedStep}
            />
        </div>
    );
};

export default JourneyPath;
